import React from "react";

const Navigation = () => {
  return (
    <div>
      <header className="header-area">
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2">
                  <div className="logo">
                    <a href="index.html">
                      <img
                        src="/assets/img/logo/logo.png"
                        width="145px"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-9 d-flex align-items-center justify-content-center">
                  <div className="responsive">
                    <i className="icon dripicons-align-right"></i>
                  </div>
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <a href="#features">Features</a>
                        </li>
                        <li>
                          <a href="#faqs">Faqs</a>
                        </li>
                        <li>
                          <a href="#pricing">Pricing</a>
                        </li>
                        <li>
                          <a
                            href="https://documentation.cartvelly.com"
                            target="_blank"
                          >
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-2 text-right d-none d-xl-block">
                  <div className="header-btn second-header-btn">
                    <a href="https://dashboard.cartvelly.com" className="btn">
                      LOGIN
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navigation;
