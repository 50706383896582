import React from "react";

const Stories = () => {
  return (
    <div id="stories">
      <section
        className="choose-area pt-100 pb-60 p-relative"
        style={{
          backgroundImage: "url(/img/shape/header-sape3.png)",
          backgroundPosition: "right center",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="chosse-img"
          style={{ backgroundImage: "url(/assets/img/bg/shop.jpg)" }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5"></div>
            <div className="col-xl-7">
              <div className="choose-wrap">
                <div
                  className="section-title w-title left-align mb-25"
                  style={{ marginTop: "30px" }}
                >
                  <h2>The ultimate Solution for effortless Online Business</h2>
                </div>
                <div className="choose-content">
                  <p>
                    Managing a server for your eCommerce site can be daunting
                    and time-consuming. Our innovative software takes the hassle
                    out of the process with a sleek dashboard. Simply create
                    your products, categories, coupons, and shipments, then
                    click to copy the link.
                  </p>
                  <p>
                    Paste the link into your eCommerce site, update your store
                    name, and you’re all set! Enjoy seamless order management
                    with our built-in payment gateway and an intuitive dashboard
                    for a top-notch user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stories;
