import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer
        className="footer-bg footer-p pt-60"
        style={{
          backgroundImage: "url(/assets/img/bg/f-bg.png)",
          backgroundPosition: "center top",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="logo mt-15 mb-15">
                    <a href="#">
                      <img src="/assets/img/logo/w-logo.png" alt="logo" />
                    </a>
                  </div>
                  <div className="footer-text mb-20">
                    <p>
                      Simplifying eCommerce with flexible content management,
                      subscriptions, and seamless scalability.
                    </p>
                  </div>
                  <div className="footer-social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5>Company News</h5>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <a href="#">Partners</a>
                      </li>
                      <li>
                        <a href="#">About Us</a>
                      </li>
                      <li>
                        <a href="#">Career</a>
                      </li>
                      <li>
                        <Link to="privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="terms">Terms & Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5>Useful Links</h5>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <a href="#features">Features</a>
                      </li>
                      <li>
                        <a href="#screencasts">Screencasts</a>
                      </li>
                      <li>
                        <a href="#faqs">Faqs</a>
                      </li>
                      <li>
                        <a href="#pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="#stories">Stories</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5>Contact Us</h5>
                  </div>
                  <div className="footer-link">
                    <div className="f-contact">
                      <ul>
                        <li>
                          <i className="icon dripicons-phone"></i>
                          <span>
                            1800-121-3637
                            <br />
                            +91 555 234-8765
                          </span>
                        </li>
                        <li>
                          <i className="icon dripicons-mail"></i>
                          <span>
                            <a href="mailto:info@example.com">
                              info@cartvelly.com
                            </a>
                            <br />
                            <a href="mailto:sale@example.com">
                              sale@cartvelly.com
                            </a>
                          </span>
                        </li>
                        <li>
                          <i className="fal fa-map-marker-alt"></i>
                          <span>
                            380 St Kilda Road, Melbourne
                            <br />
                            VIC 3004, Australia
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text">
                  <p>&copy; 2024 @ Cartvelly All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
