import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import ReactGA from "react-ga4";

// Google Analytics Tracking ID
ReactGA.initialize("G-H171FBFV9Y");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
    </div>
  );
}

export default App;
